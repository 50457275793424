import toUpper from 'lodash/fp/toUpper';

import React, { ReactElement, useMemo } from 'react';
import styled, { CSSProperties, useTheme } from 'styled-components';

import { defaultWebPalette } from '@hero-design/colors';
import { Box, Tag } from '@hero-design/react';

import AppliedIndicator from '@shared/AppliedIndicator';
import MatchingScoreTag from '@shared/MatchingScoreTag';
import SaveJobButton from '@shared/SaveJobButton';
import UnSigninSaveJobButton from '@shared/SaveJobButton/UnSigninSaveJobButton';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useWindowSize from '@shared/hooks/useWindowSize';
import {
  TRACK_SMARTMATCH_POTENTIAL_CLICK_RECOMMENDED_ROLE_TILE,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import { formatCurrency } from 'src/modules/CareersPage/utils/helpers';

import { JobItem, SearchCareerPageJobItem } from '../../../types';

import CompanyLink from './CompanyLink';
import CompanyLogo from './CompanyLogo';
import HiddenJobLogo from './HiddenJobLogo';
import RoleItemInfo from './RoleItemInfo';

import InterestedJobIcon from '../../JobMatches/InterestedJobs/InterestedJobIcon';
import SalaryTag from '../../JobMatches/ShadowJobs/SalaryTag';
import getCareerPageJobId from '../common/getCareerPageJobId';

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.medium}px;
`;

const RoleItemWrapper = styled.div<{
  selectable?: boolean;
  backgroundColor: string;
}>`
  margin-top: ${({ theme }) => theme.space.small}px;
  padding: ${({ theme }) => theme.space.medium}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => theme.radii.medium}px;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
`;

type RedesignRoleItemProps = {
  data: JobItem | SearchCareerPageJobItem;
  companyLogo?: string;
  companyName?: string;
  showStar?: boolean;
  selected?: boolean;
  onSelect?: (jobId: string) => void;
  dataTestId?: string;
  isHiddenJob?: boolean;
  isSaveCandidate?: boolean;
  onSave?: (jobId: string) => void;
  disabled?: boolean;
  CustomRightComponent?: ReactElement;
  isFromDefaultOrg?: boolean;
  containerStyles?: CSSProperties;
  isSmartMatchPotentialItem?: boolean;
};

const RedesignRoleItem = ({
  data,
  companyLogo,
  companyName,
  showStar,
  selected,
  onSelect,
  dataTestId,
  isHiddenJob = false,
  isSaveCandidate = false,
  onSave,
  disabled,
  CustomRightComponent,
  isFromDefaultOrg = false,
  containerStyles,
  isSmartMatchPotentialItem = false,
}: RedesignRoleItemProps) => {
  const { track } = useMixpanelTracking();
  const theme = useTheme();
  const { isSmallScreen } = useWindowSize();
  const { permissionsData } = useFetchPermissions();

  const swagSeoStrategy2024Enabled =
    permissionsData?.data?.swag_seo_strategy_2024_enabled || false;

  const {
    salary_min: salaryMin,
    salary_currency: salaryCurrency,
    workplace_type: workplaceType = '',
    is_saved: isSaved,
    id: jobId,
    experience_level_name: experienceLevel,
  } = data;
  const matchedType = 'matched_type' in data ? data.matched_type : undefined;
  const salaryTag = 'salary_tag' in data ? data.salary_tag : undefined;
  const hidePayRate = 'hide_pay_rate' in data ? data.hide_pay_rate : undefined;

  const careerPageJobId = useMemo(
    () => getCareerPageJobId(data, { swagSeoStrategy2024Enabled }),
    [data, swagSeoStrategy2024Enabled]
  );

  const shouldShowSalaryRange = salaryMin && !hidePayRate;
  const shouldShowRemoteTag = useMemo(
    () => ['remote_anywhere', 'remote_domestic'].includes(workplaceType || ''),
    [workplaceType]
  );

  const selectable = useMemo(() => onSelect !== undefined, [onSelect]);

  const tagStyles = isFromDefaultOrg
    ? {
        border: 'none',
        bgColor: theme.colors.defaultGreyBackground,
        color: theme.colors.bodyText,
      }
    : {};

  const backgroundColor = useMemo(() => {
    if (selected) return theme.colors.primaryLightBackground;

    return isFromDefaultOrg
      ? theme.colors.defaultBackground
      : theme.colors.defaultLightBackground;
  }, [isFromDefaultOrg, selected]);

  return (
    <RoleItemWrapper
      selectable={selectable || !disabled}
      onClick={() => {
        if (isSmartMatchPotentialItem) {
          track(TRACK_SMARTMATCH_POTENTIAL_CLICK_RECOMMENDED_ROLE_TILE, {
            jobId,
          });
        }

        onSelect?.(jobId);
      }}
      data-test-id={dataTestId}
      backgroundColor={backgroundColor}
      style={{ ...containerStyles }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          position: 'relative',
          columnGap: theme.space.medium,
          flexDirection: isSmallScreen ? 'column' : 'row',
        }}
      >
        <CompanyLink
          data={data}
          disableLink={selectable || !!disabled}
          careerPageJobId={careerPageJobId}
        />

        <ContentWrapper>
          {isHiddenJob ? (
            <HiddenJobLogo companyLogo={companyLogo} />
          ) : (
            <CompanyLogo
              companyLogo={companyLogo}
              companyName={companyName}
              extraStyles={{
                backgroundColor: 'defaultGreyBackground',
                borderRadius: 'medium',
              }}
            />
          )}

          <RoleItemInfo
            companyName={companyName}
            data={data}
            disableLink={selectable || !!disabled}
            isHiddenJob={isHiddenJob}
            isFromDefaultOrg={isFromDefaultOrg}
          />
        </ContentWrapper>

        <Box
          sx={{
            mt: isSmallScreen ? 'medium' : undefined,
            display: 'flex',
            flexDirection: isSmallScreen ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isHiddenJob ? (
            <Tag
              data-test-id={`job-${data.id}-hidden-job-tag`}
              text="EXCLUSIVE"
              variant="basic"
              sx={{
                background: defaultWebPalette.violetLight90,
                color: defaultWebPalette.violet,
                borderColor: defaultWebPalette.violet,
              }}
            />
          ) : null}

          {!isHiddenJob && matchedType ? (
            <MatchingScoreTag matchedType={matchedType} id={data.id} />
          ) : null}

          {data.last_applied_at && (
            <AppliedIndicator appliedAt={data.last_applied_at} />
          )}
        </Box>
      </Box>

      <Box sx={{ flex: 1 }} />

      <Box
        sx={{ mt: 'small', display: 'flex', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', gap: `${theme.space.small}px` }}>
          {!isHiddenJob &&
          shouldShowSalaryRange &&
          salaryMin &&
          salaryCurrency ? (
            <Tag
              text={`${formatCurrency(salaryMin, salaryCurrency)}+`}
              sx={tagStyles}
            />
          ) : null}

          {shouldShowRemoteTag && <Tag text="REMOTE" sx={tagStyles} />}
          {isFromDefaultOrg && experienceLevel && (
            <Tag text={toUpper(experienceLevel)} sx={tagStyles} />
          )}
          {isHiddenJob && <SalaryTag salaryTag={salaryTag} />}
        </Box>

        <Box sx={{ display: 'flex', gap: `${theme.space.small}px` }}>
          {isSaveCandidate ? <InterestedJobIcon /> : null}

          {!isHiddenJob &&
            showStar &&
            permissionsData?.data?.access_saved_jobs &&
            (isSaved === null ? (
              <UnSigninSaveJobButton />
            ) : (
              <SaveJobButton
                isSaved={isSaved}
                careerPageJobId={jobId}
                onSave={onSave}
                isFromDefaultOrg={isFromDefaultOrg}
              />
            ))}
          {CustomRightComponent}
        </Box>
      </Box>
    </RoleItemWrapper>
  );
};

export default RedesignRoleItem;

import { Box, Icon, Tooltip } from '@hero-design/react';

const InterestedJobIcon = () => (
  <Tooltip
    content="Your profile has been liked by a hiring manager"
    target={
      <Box
        data-test-id="saved-candidate-icon-data-test-id"
        sx={{
          width: 'medium',
          height: 'medium',
          padding: 'xsmall',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: 'primary',
          color: 'white',
        }}
      >
        <Icon icon="heart" intent="inherit" size="xsmall" />
      </Box>
    }
  />
);

export default InterestedJobIcon;

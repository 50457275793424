import React from 'react';

import { Box, Typography } from '@hero-design/react';

import {
  JobItem,
  SearchCareerPageJobItem,
} from 'src/modules/CareersPage/types';

import CompanyName from '../CompanyName';

type RoleItemInfoProps = {
  data: JobItem | SearchCareerPageJobItem;
  companyName?: string;
  disableLink: boolean;
  isHiddenJob?: boolean;
  isFromDefaultOrg?: boolean;
};

const RoleItemInfo = ({
  data,
  companyName,
  disableLink,
  isHiddenJob = false,
  isFromDefaultOrg = false,
}: RoleItemInfoProps) => (
  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
    <Typography.Text
      fontSize={16}
      intent="primary"
      fontWeight={isFromDefaultOrg ? 'regular' : 'semi-bold'}
    >
      {data.title}
    </Typography.Text>

    {!isHiddenJob ? (
      <CompanyName
        data={data}
        companyName={companyName}
        isOrgNewUI
        disableLink={disableLink}
      />
    ) : null}

    {data.vendor_location_name && (
      <Typography.Text
        intent={isFromDefaultOrg ? 'subdued' : 'body'}
        sx={{ mr: 'xsmall' }}
        tagName="span"
      >
        {data.vendor_location_name}
      </Typography.Text>
    )}
  </Box>
);

export default RoleItemInfo;

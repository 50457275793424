import React from 'react';

import { SxObject, Tag } from '@hero-design/react';

type MatchingScoreTag = {
  id: string;
  matchedType: string;
  containerSx?: SxObject;
};

export const getMatchedTypeTagIntent = (matchedType: string) => {
  if (matchedType === 'top_match') return 'success';
  if (matchedType === 'moderate_match') return 'warning';
  return 'danger';
};

export const formatMatchedType = (matchedType?: string) => {
  if (typeof matchedType !== 'string') {
    return '';
  }

  return matchedType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .toUpperCase();
};

const MatchingScoreTag = ({
  id,
  matchedType,
  containerSx,
}: MatchingScoreTag) => (
  <Tag
    data-test-id={`job-${id}-matching-score-tag`}
    intent={getMatchedTypeTagIntent(matchedType)}
    text={formatMatchedType(matchedType)}
    variant="outlined"
    sx={containerSx}
  />
);

export default MatchingScoreTag;

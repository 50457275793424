import { Box } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import { BLUR_DATA_URL } from 'src/constants';

const DEFAULT_LOGO_SIZE = 80;

type HiddenJobLogoProps = {
  companyLogo: string | undefined;
};

const HiddenJobLogo = ({ companyLogo }: HiddenJobLogoProps) => (
  <Box
    sx={{
      width: DEFAULT_LOGO_SIZE,
      height: DEFAULT_LOGO_SIZE,
      borderRadius: 'medium',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Image
      src={companyLogo ?? '/assets/images/hidden-job-logo.svg'}
      alt="hidden job logo"
      placeholder="blur"
      blurDataURL={BLUR_DATA_URL}
      layout="fill"
      objectFit="contain"
      quality={100}
    />
  </Box>
);

export default HiddenJobLogo;

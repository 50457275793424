import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import {
  TRACK_ROLE_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import {
  JobItem,
  SearchCareerPageJobItem,
} from 'src/modules/CareersPage/types';

import genJobDetailPageLink from '../common/genJobDetailPageLink';
import { ALLOW_JOB_DETAIL_PAGES } from '../constants';

const LinkWrapper = styled.a`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-decoration: none;
  z-index: 0;
`;

const CompanyLink = ({
  data,
  disableLink,
  careerPageJobId,
  onClick,
}: {
  data: JobItem | SearchCareerPageJobItem;
  disableLink: boolean;
  careerPageJobId: string;
  onClick?: () => void;
}) => {
  const router = useRouter();
  const { track } = useMixpanelTracking();

  const jobAdvertId = 'job_advert_id' in data ? data.job_advert_id : undefined;
  const sourceName = 'source_name' in data ? data.source_name : undefined;

  const onRoleClick = useCallback(() => {
    if (onClick) {
      onClick();
      return;
    }

    if (router?.pathname && router.pathname in TRACK_ROLE_CLICK) {
      track(TRACK_ROLE_CLICK[router.pathname]);
    }
  }, [router.pathname, track]);

  const href = useMemo(() => {
    const jobDetailLink = genJobDetailPageLink({
      isJobAdvert: Boolean(jobAdvertId),
      jobAdvertId: jobAdvertId || '',
      careerPageJobId,
      locale: router.locale,
    });

    return generateGetURL(jobDetailLink, {
      utm_medium: router?.query?.utm_medium,
    });
  }, [jobAdvertId, careerPageJobId, router.locale, router?.query?.utm_medium]);

  const isApplicationsPage = useMemo(
    () => router.pathname === '/user/applications',
    [router.pathname]
  );

  const hasJobDetailPage = useMemo(
    () =>
      ALLOW_JOB_DETAIL_PAGES.includes(sourceName || '') ||
      !isApplicationsPage ||
      data.is_saved,
    [data.is_saved, sourceName, isApplicationsPage]
  );

  return (
    <LinkWrapper
      href={hasJobDetailPage && !disableLink ? href : undefined}
      style={{
        cursor: hasJobDetailPage && !disableLink ? 'pointer' : 'inherit',
      }}
      rel="noreferrer"
      onClick={onRoleClick}
    />
  );
};

export default CompanyLink;

import { Tag } from '@hero-design/react';

import type { SalaryTag } from '../../../types';

const SalaryTag = ({ salaryTag }: { salaryTag?: SalaryTag }) => {
  switch (salaryTag) {
    case 'not_specified':
      return <Tag text="Salary range required" />;
    case 'below':
      return <Tag text="Below salary expectations" />;
    case 'between':
      return <Tag text="Meets salary expectations" />;
    case 'above':
      return <Tag text="Above salary expectations" />;
    default:
      return null;
  }
};

export default SalaryTag;
